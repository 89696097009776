<template>
    <div class="card">
        <h5>Extrato Detalhado Contas a Receber</h5>
		<DataTable :value="contasReceber" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="stack"
					:globalFilterFields="[
                            'codigoEstabelecimento',
                            'nomeEstabelecimento',
                            'dataEmissaoDocumento',
                            'dataVencimento',
                            'especie',
                            'codigoTitulo',
                            'unidadeNegocio',
                            'valorOriginal',
                            'valorSaldo']" 
                    sortField="codigoEstabelecimento" 
                    :sortOrder="1">
            <template #header>
                <div class="flex justify-content-between flex-column sm:flex-row">
                    <div>
                        <Button @click="$emit('showDetail', false)" icon="pi pi-backward" type="button" class="p-button-outlined mb-2" label="Voltar"/>
                        <Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                    </div>
                    <span class="p-input-icon-left mb-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filters1['global'].value" placeholder="Procurar" style="width: 100%"/>
                    </span>
                </div>
            </template>
            <template #empty>
               Nenhum registro encontrado.
            </template>
            <template #loading>
                Carregando dados. Por favor, aguarde!
            </template>
            <Column field="codigoEstabelecimento" header="Integrada" style="text-align: center; min-width:12rem;" sortable>
                <template #body="data">
                    {{data.data.codigoEstabelecimento}} - {{data.data.nomeEstabelecimento}}
                </template>
            </Column>
            <Column header="Data Emissão" field="dataEmissaoDocumento" style="text-align: center; min-width:10rem" sortable>
                <template #body="{data}">
                    {{data.dataEmissaoDocumento}}
                </template>
            </Column>
            <Column header="Data Vencimento" field="dataVencimento" style="text-align: center; min-width:10rem" sortable>
                <template #body="{data}">
                    {{data.dataVencimento}}
                </template>
            </Column>
            <Column field="especie" header="Espécie" style="text-align: center; min-width:6rem" sortable>
                <template >
                    {{especie}}
                </template>
            </Column>
            <Column field="codigoTitulo" header="Título" style="text-align: center; min-width:12rem" sortable>
                <template >
                    {{codigoTitulo}}
                </template>
            </Column>
            <Column field="unidadeNegocio" header="Unidade Negócio" style="text-align: center; min-width:12rem" sortable>
                <template >
                    {{unidadeNegocio}}
                </template>
            </Column>
             <Column header="Total" field="valorOriginal" dataType="numeric" style="text-align: right; min-width:10rem" sortable>
                <template #body="{data}">
                    <span class="text-bold label-blue">{{formatCurrency(data.valorOriginal)}}</span>
                </template>
            </Column>
           <Column header="Saldo" field="valorSaldo" dataType="numeric" style="text-align: right; min-width:10rem" sortable>
                <template #body="{data}">
                    <span class="text-bold label-red">{{formatCurrency(data.valorSaldo)}}</span>
                </template>
            </Column>

            <ColumnGroup type="footer">
                <Row>
                    <Column footer="Total:" :colspan="6" footerStyle="text-align:right"/>
                    <Column style="text-align: center;" :footer="formatCurrency(totalGeral)"/>
                    <Column style="text-align: center;" :footer="formatCurrency(totalSaldo)"/>
                </Row>
            </ColumnGroup>
        </DataTable>

    </div>     
</template>

<script >
    import ContasReceberService from '../../service/ContasReceberService'
    import {FilterMatchMode,FilterOperator} from 'primevue/api'
    import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'ContasReceberDetalhado',
		props: {
			matricula: {
				type: Number,
				required: true
			},
			especie: {
				type: String,
				required: true
			},
			unidadeNegocio: {
				type: String,
				required: true
			}
		},
        data() {
            return {
                contasReceber: [],
				totalGeral: null,
				totalSaldo: null,
                filters1: null,
				filters2: {},
				loading1: false,
            }
        },

		created() {
			this.getContasReceberDetalhada();
            this.initFilters1();
		},
        methods: {
            getContasReceberDetalhada() {
                this.carregarLoading();
                ContasReceberService.getContasReceberDetalhada (
					this.$props.matricula,
					this.$props.especie,
					this.$props.unidadeNegocio)
                    .then((response) => {
                        this.contasReceber = response.data.detalhes;

                        this.contasReceber.map(c => {
                            c.dataEmissaoDocumento = this.formatDate(c.dataEmissaoDocumento);
                            c.dataVencimento = this.formatDate(c.dataVencimento);
                        });

                        this.totalGeral = response.data.totalGeral;
                        this.totalSaldo = response.data.totalSaldo;
                    })
                    .finally(() => {
                        this.carregarLoading();
                    });
            },

            initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'codigoEstabelecimento': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'nomeEstabelecimento': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'dataEmissaoDocumento': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'dataVencimento': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},					
					'especie': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'codigoTitulo': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'unidadeNegocio': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'valorOriginal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'esvalorSaldopecie': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
				}

			}, 
            
            clearFilter1() {
				this.initFilters1();
			},
            
            formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
			},
			
            formatDate(data) {                            
            	return Formatacao.formatDate(data);
			},

            carregarLoading() {
                this.loading1 = !this.loading1;
            },
        }     
       
    }
</script>

<style scoped lang="scss">
    @import '../../assets/demo/styles/badges.scss';

    button{
		margin-right: 8px;
	}

	.label-blue{
		color: #0066cc;
	}
	.label-red{
		color: #B40404;
	}
    .p-datatable-table .p-column-header-content {
        display: inline-flex !important;
    }
</style>