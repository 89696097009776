<template>   
    <ContasReceberResumido v-if="!showDetalhe" v-on:showDetail="showDetail" :matricula="currentUser.matricula"/>
    <ContasReceberDetalhado v-if="showDetalhe" v-on:showDetail="showDetail" 
                          :matricula="currentUser.matricula"
                          :especie="especie"
                          :unidade-negocio="unidadeNegocio"/>
</template>

<script lang="js">
    import ContasReceberResumido from '../../components/extrato/ContasReceberResumido.vue';
    import ContasReceberDetalhado from '../../components/extrato/ContasReceberDetalhado.vue';

    export  default {
        data(){
            return {
                showDetalhe: false,
                especie: null,
                unidadeNegocio: null
            }
        },
        props: {
            conta: Object
        },
        methods: {
            showDetail(value, especie, unidadeNegocio) {
                this.showDetalhe = value;
                if(value){
                    this.especie = especie;
                    this.unidadeNegocio = unidadeNegocio;
                }
            }
        },
		computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { ContasReceberResumido, ContasReceberDetalhado }
    }

</script>
<style lang="sass" scoped>
    
</style>