import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const CONTA_RECEBER_API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/conta-a-receber`;


class ContasReceberService {

    getContasReceberResumo(matricula) {
        return axios.get(CONTA_RECEBER_API_BASE_URL + RESOURCE_V1 +"/resumido/"+matricula);
    }

    getContasReceberDetalhada(matricula, codigoEspecie, codigoUnidadeNegocio) {
        return axios.get(CONTA_RECEBER_API_BASE_URL + RESOURCE_V1 +"/detalhado/"+matricula+"/"+codigoEspecie+"/"+codigoUnidadeNegocio);
    }
    
}

export default new ContasReceberService();