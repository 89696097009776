<template>
    <div class="card">
        <h5>Contas a Receber</h5>

        <DataTable :value="contasReceber" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="stack"
					:globalFilterFields="['codigoEspecie','especie','codigoUnidadeNegocio','unidadeNegocio','valorOriginal','valorSaldo']"
                    sortField="codigoEspecie" 
                    :sortOrder="1">
            <template #header>
                <div class="flex justify-content-between flex-column sm:flex-row">
                    <Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                    <span class="p-input-icon-left mb-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filters1['global'].value" placeholder="Procurar" style="width: 100%"/>
                    </span>
                </div>
            </template>
            <template #empty>
               Nenhum registro encontrado.
            </template>
            <template #loading>
                Carregando dados. Por favor, aguarde!
            </template>
            <Column style="text-align: center; min-width:2rem;">
                <template #body="slotProps">
                    <Button @click="$emit('showDetail', true, slotProps.data.codigoEspecie, slotProps.data.codigoUnidadeNegocio)" 
                        type="button" class="mr-2 justify-content-center" label="Detalhar" icon="pi pi-search"></Button>
                </template>
            </Column>
            <Column field="codigoEspecie" header="Espécie" style="text-align: left; min-width:12rem;" sortable>
                <template #body="slotProps">
                    {{slotProps.data.codigoEspecie}} - {{slotProps.data.especie}}
                </template>
            </Column>
            <Column field="codigoUnidadeNegocio" header="Cód. Unidade Negócio" style="text-align: center; min-width:6rem" sortable>
                <template >
                    {{codigoUnidadeNegocio}}
                </template>
            </Column>
            <Column field="unidadeNegocio" header="Unidade Negócio" style="text-align: center; min-width:12rem" sortable>
                <template>
                    {{unidadeNegocio}}
                </template>
            </Column>
            <Column header="Total" field="valorOriginal" style="text-align: right; min-width:10rem" sortable>
                <template #body="{data}">
                    {{ formatCurrency(data.valorOriginal) }}
                </template>
            </Column>
           <Column header="Saldo" field="valorSaldo" dataType="numeric" style="text-align: right; min-width:10rem" sortable>
                <template #body="{data}">
                    <span class="text-bold label-red">{{formatCurrency(data.valorSaldo)}}</span>
                </template>
            </Column>			
            <ColumnGroup type="footer">
                <Row>
                    <Column footer="Total:" :colspan="4" footerStyle="text-align: right;"/>
                    <Column :footer="formatCurrency(totalGeral)" footerStyle="text-align: right; color: #0066cc"/>
                    <Column :footer="formatCurrency(totalSaldo)" footerStyle="text-align: right; color: #B40404"/>
                </Row>
            </ColumnGroup>
        </DataTable>
    </div>     
</template>

<script >
    import ContasReceberService from '../../service/ContasReceberService'
    import {FilterMatchMode,FilterOperator} from 'primevue/api'
    import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'ContasReceberResumido',

        
        props: {
			matricula: {
				type: Number,
				required: true
			}
		},
        data() {
            return {
                contasReceber: [],
				totalGeral: null,
				totalSaldo: null,
                filters1: null,
				filters2: {},
				loading1: false,
            }
        },

		created() {
			this.getContasReceberResumido();
            this.initFilters1();
		},
        methods: {
            getContasReceberResumido() {
                this.carregarLoading();
                ContasReceberService.getContasReceberResumo(this.$props.matricula)
                    .then((response) => {
                        this.contasReceber = response.data.detalhes;
                        this.totalGeral = response.data.totalGeral;
                        this.totalSaldo = response.data.totalSaldo;
                    })
                    .finally(() => {
                        this.carregarLoading();
                    });
            },
          
            initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'codigoEspecie': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'codigoUnidadeNegocio': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'unidadeNegocio': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'valorOriginal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},					
					'valorSaldo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
				}
			}, 
          
            clearFilter1() {
				this.initFilters1();
			},            
          
            formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
			},

            carregarLoading() {
                this.loading1 = !this.loading1;
            },
        }
    }
</script>

<style>
    @import '../../assets/demo/styles/badges.scss';

	.label-blue{
		color: #0066cc;
	}
	.label-red{
		color: #B40404;
	}
    .p-datatable-table .p-column-header-content {
        display: inline-flex !important;
    }

</style>